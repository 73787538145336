<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                {{ $t('products.campaigns.add-campaign') }}
                <el-button
                    type="primary"
                    size="mini"
                    style="float: right"
                    icon="el-icon-check"
                    plain
                    @click.native="addCampaign"
                    >{{ $t('products.campaigns.add-campaign') }}</el-button
                >
            </div>
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <div class="sub-title">{{ $t('products.campaigns.campaign-name') }}</div>
                    <el-input v-model="newCampaign.name"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <div class="sub-title">{{ $t('products.campaigns.campaign-start') }}</div>
                    <el-date-picker class="fullWidth" v-model="newCampaign.start" type="date"> </el-date-picker>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <div class="sub-title">{{ $t('products.campaigns.campaign-end') }}</div>
                    <el-date-picker class="fullWidth" v-model="newCampaign.end" type="date"> </el-date-picker>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <div class="sub-title">{{ $t('products.campaigns.campaign-type') }}</div>
                    <el-select class="fullWidth" v-model="newCampaign.type">
                        <el-option :value="1" label="Promotie"></el-option>
                        <el-option :value="2" label="Lichidare"></el-option>
                    </el-select>
                </el-col>
            </el-row>
        </el-card>
        <div class="separator"></div>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                {{ $t('products.campaigns.campaign-filters') }}
                <span style="float: right">
                    <el-button type="primary" size="mini" icon="el-icon-check" plain @click.native="applyFilters">{{
                        $t('general.apply')
                    }}</el-button>
                    <el-button type="warning" size="mini" icon="el-icon-refresh" plain @click.native="resetFilters">
                        {{ $t('general.reset') }}</el-button
                    >
                </span>
            </div>
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <div class="sub-title">{{ $t('products.campaigns.search') }}</div>
                    <el-input
                        v-model="filters.search.value"
                        class="input-with-select"
                        :placeholder="$t('products.campaigns.search-filter')"
                        @keyup.enter.native="applyFilters"
                    >
                        <el-button slot="append" icon="el-icon-search" @click.native="applyFilters(true)"></el-button>
                    </el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <div class="sub-title">{{ $t('products.campaigns.campaign-start') }}</div>
                    <el-date-picker
                        class="fullWidth"
                        v-model="filters.minDate.value"
                        value-format="yyyy-MM-dd"
                        type="date"
                        :placeholder="$t('products.campaigns.campaign-start-ph')"
                    >
                    </el-date-picker>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <div class="sub-title">{{ $t('products.campaigns.campaign-end') }}</div>
                    <el-date-picker
                        class="fullWidth"
                        v-model="filters.maxDate.value"
                        value-format="yyyy-MM-dd"
                        type="date"
                        :placeholder="$t('products.campaigns.campaign-end-ph')"
                    >
                    </el-date-picker>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8">
                    <div class="sub-title">{{ $t('products.campaigns.active-campaigns') }}</div>
                    <el-checkbox v-model="onlyActive"></el-checkbox>
                </el-col>
            </el-row>
        </el-card>
        <div class="separator"></div>
        <el-row :gutter="12">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    Model fisier
                    <el-button size="mini" type="primary" @click.native="downloadCampaignModel" style="float: right">
                        {{ $t('products.campaigns.model-download') }}</el-button
                    >
                </div>
                <el-col>
                    <!--<el-button size="mini" type="success" @click="uploadCampaignFile">Incarca fisier</el-button>-->
                    <div class="separator"></div>
                    <el-checkbox size="small" v-model="updatePrincipal" style="margin-bottom: 10px">
                        {{ $t('products.campaigns.update-principal') }}</el-checkbox
                    >

                    <el-upload
                        ref="uploadComp"
                        id="importFile"
                        list-type="picture"
                        :auto-upload="false"
                        :on-remove="removeFile"
                        class="upload-demo"
                        :limit="1"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        style="width: 100%"
                    >
                        <!-- <div slot="tip" class="el-upload__tip">Doar fisiere de tip JPG, GIF si PNG sunt acceptate</div> -->
                        <el-button size="small" type="primary" icon="el-icon-document">
                            {{ $t('products.campaigns.upload-model') }}</el-button
                        >
                    </el-upload>
                    <div class="separator"></div>
                    <el-button
                        type="primary"
                        style="width: 100%"
                        icon="el-icon-check"
                        plain
                        @click.native="uploadCampaignFile"
                        >{{ $t('general.send') }}</el-button
                    >
                </el-col>
            </el-card>
        </el-row>
        <div class="separator"></div>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                {{ $t('products.campaigns.campaign-list') }}
            </div>
            <div class="center">
                <el-pagination
                    layout="prev, pager, next, total"
                    :current-page="currentPage"
                    :total="count"
                    :page-size="limit"
                    @current-change="changePage"
                >
                </el-pagination>
            </div>
            <el-table :data="campaigns" border style="width: 100%">
                <el-table-column prop="id_campanie" :label="$t('general.id')"> </el-table-column>
                <el-table-column prop="nume_campanie" :label="$t('general.name')"> </el-table-column>
                <el-table-column prop="discount" :label="$t('products.campaigns.discount')" width="120px">
                </el-table-column>
                <el-table-column :label="$t('products.campaigns.date-start')" width="120px">
                    <template slot-scope="scope">
                        {{ formatTime(campaigns[scope.$index].data_start) }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('products.campaigns.date-end')" width="120px">
                    <template slot-scope="scope">
                        {{ formatTime(campaigns[scope.$index].data_end) }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.actions')" width="105px">
                    <template slot-scope="scope">
                        <router-link :to="{ path: gotoAddress(scope.$index, campaigns) }">
                            <el-button size="mini" type="primary" icon="el-icon-info" plain
                                >{{ $t('general.details') }}
                            </el-button>
                        </router-link>
                    </template>
                </el-table-column>
            </el-table>
            <div class="center">
                <el-pagination
                    layout="prev, pager, next, total"
                    :current-page="currentPage"
                    :total="count"
                    :page-size="limit"
                    @current-change="changePage"
                >
                </el-pagination>
            </div>
        </el-card>
        <el-dialog :title="$t('publish.import.import-result')" :visible.sync="importReportDialog" width="90%">
            <span>
                <div class="separator"></div>
                <div class="sub-title" style="font-size: 17px; color: red">
                    <i class="el-icon-warning"> &nbsp;</i>{{ $t('publish.import.product-prob') }}:
                    <strong
                        >{{ importProblems == false ? 0 : importProblems.length }}
                        {{ $t('publish.import.products') }}</strong
                    >
                </div>
                <div class="sub-title" style="font-size: 17px; color: green">
                    <i class="el-icon-success"></i> &nbsp;{{ $t('publish.import.product-accept') }}:
                    <strong>{{ acceptedProducts }} {{ $t('publish.import.products') }}</strong>
                </div>
                <div class="separator"></div>
                <div class="sub-title">
                    <strong>{{ $t('publish.import.not-accept') }}: </strong>
                </div>
                <el-table
                    :data="importProblems"
                    style="width: 100%; height: auto; max-height: 500px; overflow-y: auto"
                    size="small"
                    v-if="importProblems"
                >
                    <el-table-column prop="model" :label="$t('publish.import.line')" width="150px"> </el-table-column>
                    <el-table-column prop="message" :label="$t('publish.import.reason')"> </el-table-column>
                </el-table>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="importReportDialog = false">{{ $t('general.close') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getCampaigns, addCampaign, getCampaignModel, uploadCampaignFile } from '@/api/produse';
import moment from 'moment';

import { debugPromise, setParams } from '@/utils/index';

export default {
    props: ['filterQuery'],
    data() {
        return {
            newCampaign: {
                name: '',
                start: '',
                end: '',
                type: 1,
            },
            filters: {
                search: {
                    type: 'input',
                    value: '',
                },
                minDate: {
                    type: 'select',
                    value: '',
                },
                maxDate: {
                    type: 'select',
                    value: '',
                },
                start: {
                    type: 'page',
                    value: 0,
                },
            },
            campaigns: [],
            currentPage: 0,
            count: 0,
            limit: 0,
            importReportDialog: false,
            importProblems: false,
            acceptedProducts: 0,
            updatePrincipal: false,
            onlyActive: false,
        };
    },
    methods: {
        removeFile() {
            document.getElementById('importFile').querySelector('.el-upload__input').value = '';
        },
        changePage(page) {
            this.filters.start.value = this.limit * (page - 1);
            this.currentPage = page;

            this.getCampaigns();
        },
        addCampaign() {
            if (this.newCampaign.name.length < 5) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('products.campaigns.error-name'),
                });
                return false;
            }
            if (this.newCampaign.start.length < 1) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('products.campaigns.error-date-start'),
                });
                return false;
            }
            if (this.newCampaign.end.length < 1) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('products.campaigns.error-date-end'),
                });
                return false;
            }

            addCampaign({
                newCampaignData: {
                    numecampanie: this.newCampaign.name,
                    datastart: moment(this.newCampaign.start).format('YYYY-MM-DD'),
                    dataend: moment(this.newCampaign.end).format('YYYY-MM-DD'),
                    type: this.newCampaign.type,
                },
            })
                .then((res) => {
                    this.getCampaigns();
                    this.resetAddFields();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'addCampaign',
                            params: {
                                newCampaignData: {
                                    numecampanie: this.newCampaign.name,
                                    datastart: moment(this.newCampaign.start).format('YYYY-MM-DD'),
                                    dataend: moment(this.newCampaign.end).format('YYYY-MM-DD'),
                                    type: this.newCampaign.type,
                                },
                            },
                        },
                    });
                    debugPromise('addCampaign', e);
                });
        },
        resetAddFields() {
            this.newCampaign = {
                name: '',
                start: '',
                end: '',
            };
        },
        getCampaigns() {
            const filters = {};
            for (const prop in this.filters) {
                filters[prop] = this.filters[prop].value;
            }
            filters['onlyActive'] = this.onlyActive;
            getCampaigns(filters)
                .then((res) => {
                    if ('undefined' == typeof res) {
                        this.$router
                            .push({
                                name: 'Profil public',
                            })
                            .catch(() => {});
                        return;
                    }
                    if (typeof res.message === 'undefined') return false;
                    this.campaigns = res.message.data;

                    this.count = res.message.count;
                    this.currentPage =
                        res.message.start / res.message.limit + 1 > 0 ? res.message.start / res.message.limit + 1 : 1;
                    this.limit = res.message.limit;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getCampaigns',
                            params: filters,
                        },
                    });
                    debugPromise('getCampaigns', e);
                });
        },
        gotoAddress(index, list) {
            return '/produse/campanie/' + btoa(list[index].id_campanie);
        },
        applyFilters(fromSearch) {
            if (fromSearch === true && this.filters.search.value.length < 3) return false;
            if (typeof this.updateQuery === 'function') this.updateQuery();
            this.getCampaigns();
        },
        resetFilters() {
            this.filters = {
                search: {
                    type: 'input',
                    value: '',
                },
                minDate: {
                    type: 'select',
                    value: '',
                },
                maxDate: {
                    type: 'select',
                    value: '',
                },
                start: {
                    type: 'page',
                    value: 0,
                },
            };
            this.getCampaigns();
        },
        formatTime(time) {
            return this.$moment(time * 1000).format('L');
        },
        downloadCampaignModel() {
            getCampaignModel()
                .then((res) => {
                    const link = document.createElement('a');
                    const file = 'campaign_model.xlsx';
                    link.href = res.message.file;
                    link.download = file;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    link.remove();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getCampaignModel',
                        },
                    });
                    debugPromise('getCampaignModel', e);
                });
        },
        uploadCampaignFile() {
            const file = document.getElementById('importFile').querySelector('.el-upload__input').files;
            if (file.length < 1) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('publish.import.no-file'),
                });
                return false;
            }

            const blob = file[0];

            let params = {};
            params = setParams(params);
            params.append('fisier', blob);
            params.append('updateSite', this.updatePrincipal);

            uploadCampaignFile(params)
                .then((res) => {
                    if (typeof res.message === 'undefined') {
                        return false;
                    }
                    if (typeof res.message.accepted === 'undefined') {
                        this.sbMsg({
                            type: 'warn',
                            message:
                                'Eroare la adaugarea produselor in campanie. Verificati daca s-au adaugat o parte din produse.',
                        });
                        return false;
                    }
                    if (typeof res.message.rejected !== 'undefined' && res.message.rejected.length > 0) {
                        this.importProblems = res.message.rejected;
                    }

                    this.acceptedProducts = res.message.accepted;
                    this.importReportDialog = true;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'uploadCampaignFile',
                            params,
                        },
                    });
                    console.log(e);
                });
        },
    },
    computed: {},
    watch: {},
    created() {
        this.getCampaigns();
    },
};
</script>
