import fetch from '@/utils/fetch';

// For /produse/lista
export function getProducts(data) {
    return fetch({
        url: '/products/readProducts',
        method: 'post',
        data: data,
    });
}

export function syncCategoryWarranty(data) {
    return fetch({
        url: '/products/syncCategoryWarranty',
        method: 'post',
        data: data,
    });
}

export function saveProducts(data) {
    return fetch({
        url: '/products/saveProducts',
        method: 'post',
        data: data,
    });
}

export function getFilters(data) {
    return fetch({
        url: '/products/listFilters',
        method: 'post',
        data: data,
    });
}

export function addBonus(data) {
    return fetch({
        url: '/products/addBonus',
        method: 'post',
        data: data,
    });
}

export function getBonus(data) {
    return fetch({
        url: '/products/getBonus',
        method: 'post',
        data: data,
    });
}

export function deleteBonus(data) {
    return fetch({
        url: '/products/deleteBonus',
        method: 'post',
        data: data,
    });
}

export function exportProducts(data) {
    return fetch({
        url: '/products/exportProducts',
        method: 'post',
        data: data,
    });
}

export function cloneProduct(data) {
    return fetch({
        url: '/products/cloneProduct',
        method: 'post',
        data: data,
    });
}

// For /produse/in-asteptare
export function getWaitingProducts(data) {
    return fetch({
        url: '/products/getWaitingProducts',
        method: 'post',
        data: data,
    });
}

export function deleteWaitingProducts(data) {
    return fetch({
        url: '/products/deleteProducts',
        method: 'post',
        data: data,
    });
}

// For /produse/respinse
export function getRejectedProducts(data) {
    return fetch({
        url: '/products/getRejectedProducts',
        method: 'post',
        data: data,
    });
}

export function deleteRejectedProducts(data) {
    return fetch({
        url: '/products/deleteProducts',
        method: 'post',
        data: data,
    });
}

// For /produse/campanii

export function getCampaigns(data) {
    return fetch({
        url: '/campaign/activeCampaigns',
        method: 'post',
        data: data,
    });
}

export function addCampaign(data) {
    return fetch({
        url: '/campaign/newCampaign',
        method: 'post',
        data: data,
    });
}

// For /produse/campanie
export function readCampaign(data) {
    return fetch({
        url: '/campaign/readCampaign',
        method: 'post',
        data: data,
    });
}

export function addProductCampaign(data) {
    return fetch({
        url: '/campaign/AddProduct',
        method: 'post',
        data: data,
    });
}

export function removeProductCampaign(data) {
    return fetch({
        url: '/campaign/RemoveProduct',
        method: 'post',
        data: data,
    });
}

export function saveProductCampaign(data) {
    return fetch({
        url: '/campaign/SaveProduct',
        method: 'post',
        data: data,
    });
}

export function saveCampaign(data) {
    return fetch({
        url: '/campaign/SaveCampaign',
        method: 'post',
        data: data,
    });
}

// Form /produse/produs
export function updateProduct(data) {
    return fetch({
        url: '/products/updateProduct',
        method: 'post',
        data: data,
    });
}

export function updateStatus(data) {
    return fetch({
        url: '/products/updateStatus',
        method: 'post',
        data: data,
    });
}

export function updatePromotion(data) {
    return fetch({
        url: '/products/updatePromotion',
        method: 'post',
        data: data,
    });
}

// From /produse/produs-asteptare
export function getWaitingProduct(data) {
    return fetch({
        url: '/products/getWaitingProduct',
        method: 'post',
        data: data,
    });
}
export function updateWaitingProduct(data) {
    return fetch({
        url: '/products/updateWaitingProduct',
        method: 'post',
        data: data,
    });
}
export function getRestrictedCharacteristics(data) {
    return fetch({
        url: '/products/getRestrictedCharacteristicsForCategory',
        method: 'post',
        data: data,
    });
}

// From /produse/familii
export function getProductsFamilies(data) {
    return fetch({
        url: '/products/getProductsFamilies',
        method: 'post',
        data,
    });
}

export function addProductsFamily(data) {
    return fetch({
        url: '/products/addProductsFamily',
        method: 'post',
        data,
    });
}

// From /produse/familie
export function getFamilyDetails(data) {
    return fetch({
        url: '/products/getFamilyDetails',
        method: 'post',
        data,
    });
}

export function getProductsForFamily(data) {
    return fetch({
        url: '/products/getProductsForFamily',
        method: 'post',
        data,
    });
}

export function removeProductFromFamily(data) {
    return fetch({
        url: '/products/removeProductFromFamily',
        method: 'post',
        data,
    });
}

export function addProductToFamily(data) {
    return fetch({
        url: '/products/addProductToFamily',
        method: 'post',
        data,
    });
}

export function getSupplierManufacturers(data) {
    return fetch({
        url: '/products/getSupplierManufacturers',
        method: 'post',
        data,
    });
}

export function ajaxUploadImage(data) {
    return fetch({
        url: '/products/ajaxUploadImage',
        method: 'post',
        data,
    });
}

export function getCampaignModel(data) {
    return fetch({
        url: '/campaign/getCampaignModel',
        method: 'post',
        data: data,
    });
}

export function uploadCampaignFile(data) {
    return fetch({
        url: '/campaign/uploadCampaignFile',
        method: 'post',
        data: data,
    });
}

export function setCampaignStoc(data) {
    return fetch({
        url: '/campaign/setCampaignStoc',
        method: 'post',
        data: data,
    });
}

export function markAllProductsAsActive(data) {
    return fetch({
        url: '/products/markAllProductsAsActive',
        method: 'post',
        data: data,
    });
}

export function markAllProductsAsInactive(data) {
    return fetch({
        url: '/products/markAllProductsAsInactive',
        method: 'post',
        data: data,
    });
}

export function addAllUsingFilters(data) {
    return fetch({
        url: '/campaign/addAllUsingFilters',
        method: 'post',
        data: data,
    });
}

export function updateStockAndPrice(data) {
    return fetch({
        url: '/products/updateStockAndPrice',
        method: 'post',
        data: data,
    });
}

export function getAllProductsFamilies() {
    return fetch({
        url: '/products/getAllProductsFamilies',
        method: 'post',
    });
}

export function getProductsFamilyForCategory(data) {
    return fetch({
        url: '/products/getProductsFamilyForCategory',
        method: 'post',
        data,
    });
}

export function getWarrantyData(data) {
    return fetch({
        url: '/products/getWarrantyData',
        method: 'post',
        data,
    });
}

export function removeAllProductsFromCampaign(data) {
    return fetch({
        url: '/campaign/removeAllProductsFromCampaign',
        method: 'post',
        data,
    });
}

export function deleteProduct(data) {
    return fetch({
        url: '/products/deleteProduct',
        method: 'post',
        data,
    });
}

export function toggleProductAdvertise(data) {
    return fetch({
        url: '/products/toggleProductAdvertise',
        method: 'post',
        data,
    });
}

export function getAdvertiseInfo(data) {
    return fetch({
        url: '/products/getAdvertiseInfo',
        method: 'post',
        data,
    });
}
